<!--
 * @Autor: oops.liu
-->
<template>
  <div class="problem-box">
    <scroller>
      <div class="content">
        <p class="title">
          <!-- {{ info.title }} -->
          商品买错了怎么办？
        </p>
        <div class="details">
          您的订单已提交售后服务单，如是18:00前提交申请，一般将在当天22:00前审核完毕，18:00后提交申请，一般将在次日12:00前审核完毕，请您耐心等待并关注服务单审核结果。
        </div>
        <!-- <div class="details" v-html="info.description"></div> -->
        <p class="screen-title">您可能还想了解：</p>
        <div class="btns">
          <div v-for="item in screen" :key="item.id">
            <van-button class="btn" @click="screenClick(item.id)">{{
              item.title
            }}</van-button>
          </div>
        </div>
        <div class="hint">————以上方案是否解决了您的问题？————</div>
        <div class="submit_box flex_sb" v-if="submit_status === 1">
          <van-button class="btn" @click="resolve"
            ><img src="~@/assets/img/index/2.png" alt="" /> 已解决
          </van-button>
          <van-button class="btn" @click="unResolve"
            ><img src="~@/assets/img/index/1.png" alt="" /> 未解决
          </van-button>
        </div>
        <div class="submited_box" v-else>
          <span class="btn" v-if="submit_status === 2"
            ><img src="~@/assets/img/index/3.png" alt="" /> 已解决
          </span>
          <span class="btn" v-else-if="submit_status === 3"
            ><img src="~@/assets/img/index/4.png" alt="" /> 未解决
          </span>
        </div>
      </div>
    </scroller>
    <div class="pop_box">
      <van-popup
        v-model="showPop"
        closeable
        round
        position="bottom"
        :style="{ height: '60%' }"
      >
        <div class="content_pop">
          <p class="pop_title">感谢您的反馈</p>
          <div class="check_box">
            <p class="check_item active">描述不清楚</p>
            <p class="check_item">字太多，不想读</p>
            <p class="check_item">有错别字</p>
            <p class="check_item">其他</p>
          </div>
          <div class="input_box">
            <van-field
              v-model="message"
              rows="2"
              type="textarea"
              maxlength="300"
              placeholder="请您描述具体原因"
              show-word-limit
            />
          </div>
          <div class="submit_box">
            <van-button class="btn" block @click="submit">提交反馈 </van-button>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {},
  data() {
    return {
      info: undefined,
      list: [],
      screen: [
        { id: 1, title: "售后政策说明" },
        { id: 2, title: "如何申请售后？" },
        { id: 3, title: "如何查看商品享受哪些售后服务？" },
      ],
      submit_status: 1, //1,未提交，2已解决，3，未解决
      showPop: false,
      message: "",
    };
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id);
    },
  },
  created() {
    this.getproblemList().then((result) => {
      const data = result.data;
      this.list = data;
      const arr = [];
      for (const item of data) {
        if (item.id === this.id) {
          this.info = item;
        } else {
          arr.push(item);
        }
      }
      this.screen = arr;
    });
  },
  mounted() {},
  watch: {},
  methods: {
    ...mapActions({
      getproblemList: "refund/getproblemList",
    }),
    screenClick(id) {
      const arr = [];
      for (const item of this.list) {
        if (id === item.id) {
          this.info = item;
        } else {
          arr.push(item);
        }
      }
      this.screen = arr;
    },
    resolve() {
      this.submit_status = 2;
      this.$toast({
        message: "感谢您的反馈！",
        className: "p-l-r",
      });
    },
    unResolve() {
      this.showPop = true;
    },
    submit() {
      this.showPop = false;
      this.submit_status = 3;
      this.$toast({
        message: "感谢您的反馈！",
        className: "p-l-r",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.problem-box {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  .content {
    padding: 20px 10px;
    .title {
      padding-bottom: 12px;
      font-size: 16px;
    }
    .details {
      font-size: 14px;
      color: #333;
      padding-bottom: 30px;
    }
    .screen-title {
      font-size: 14px;
    }
    .btns {
      .btn {
        color: #0099ff;
        background-color: transparent;
        border: 0;
        padding: 10px 0;
        font-size: 14px;
      }
    }
    .hint {
      color: #999;
      font-size: 12px;
      text-align: center;
      padding: 30px 0;
    }
    .submit_box {
      width: 100%;
      box-sizing: border-box;
      padding: 0 50px;
      .btn {
        font-size: 14px;
        color: #666;
        padding: 0 20px;
        border: 1px solid #b5b5b5;
        border-radius: 18px;
        height: 36px;
        line-height: 36px;
        background: #f2f2f2;
        img {
          width: 21px;
          height: 21px;
          transform: translateY(4px);
        }
      }
    }
    .submited_box {
      text-align: center;
      padding: 30px;
      .btn {
        display: inline-block;
        font-size: 14px;
        color: #ff0000;
        padding: 0 20px;
        border: 1px solid #ff0000;
        border-radius: 18px;
        height: 36px;
        line-height: 36px;
        background: #f2f2f2;
        img {
          width: 21px;
          height: 21px;
          transform: translateY(4px);
        }
      }
    }
  }
  .pop_box {
    .content_pop {
      width: 100%;
      box-sizing: border-box;
      padding: 18px 10px;
      p {
        font-size: 14px;
        color: #333;
        text-align: center;
        font-weight: bold;
      }
      .check_box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 18px 0;
        .check_item {
          font-size: 14px;
          color: #333;
          line-height: 29px;
          padding: 0 13px;
          border-radius: 15px;
          background: #f2f2f2;
          overflow: hidden;
          margin-right: 15px;
          margin-bottom: 18px;
        }
        .active {
          color: #f00;
          border: 1px solid #f00;
          background: #fee4e4;
        }
      }
      .input_box {
        ::v-deep .van-cell {
          background: #f2f2f2;
          padding: 10px;
          border-radius: 4px;
        }
      }
      .submit_box {
        box-sizing: border-box;
        padding: 18px;
        .btn {
          height: 44px;
          background: #ff0000;
          color: #fff;
          border-radius: 22px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
